import React from "react-router-dom";

const PDFViewer = ({file}, date) => {
    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <iframe src={file} width="100%" height="250px" />
            <a href={file} download={`pitch_report_${date}.pdf`} target="_blank" style={{textDecoration: "None", color: "white" }}>
                Download PDF
            </a>
        </div>
    );
};
export default PDFViewer;