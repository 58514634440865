import React from "react";
import Typography from "@mui/material/Typography";

const PitchData = ({pitchData}) => {
    return (
        <div className="pitch-report-pitch-data">
            {pitchData.map(pitch => {
                return (
                <div className="pitch-data-section">
                    <Typography variant="h5" component="h3">
                        {pitch.pitch_name}
                    </Typography>
                    <hr style={{marginTop: "0rem"}}/>
                    <div className="pitch-report-pitch-metrics">
                        <div className="pitch-report-pitch-metric">
                            <div>Velocity</div>
                            <div>{pitch.velocity}</div>
                        </div>
                        <div className="pitch-report-pitch-metric">
                            <div>Spin Rate</div>
                            <div>{pitch.spin}</div>
                        </div>
                        <div className="pitch-report-pitch-metric">
                            <div>Vertical Break</div>
                            <div>{pitch.vertical_break}</div>
                        </div>
                        <div className="pitch-report-pitch-metric">
                            <div>Horizontal Break</div>
                            <div>{pitch.horizontal_break}</div>
                        </div>                      
                    </div>
                </div>
                )
            })}
        </div>
    );
};

export default PitchData;