import React, { useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import PitchReportDateSelector from "./PitchReportDateSelector";
import ReportDetails from "./PitchReportCard/ReportDetails";

const PitchReportReport = ({isStaff, athlete}) => {
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState("");
    const [reportData, setReportData] = useState({});
    const [error, setError] = useState(false);

    useEffect(() => {
        setError(false);

        const fetchReport = async () => {
            setLoading(true);
            await fetch(`/api/pitch-report/get-full-report/${date.id}/`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    const errorData = response.json();
                    throw new Error(errorData.detail);
                }
            })
            .then(data => setReportData(data))
            .catch(error => {
                setError(true);
                console.log(error);
            })
            .finally(setLoading(false))
        };

        setReportData({});

        if (date && athlete) {
            fetchReport();
        }
        if (date && !isStaff) {
            fetchReport();
        }
    }, [date]);

    return (
        <div className="pitch-report-report-contianer">
            <PitchReportDateSelector isStaff={isStaff} date={date} setDate={setDate} athlete={athlete}/>
            {loading && <CircularProgress />}
            {error && <p>An Error Occured</p>}
            {Object.keys(reportData).length !== 0 && <ReportDetails date={date.date} notes={reportData.notes} file={reportData.document_file} pitchData={reportData.pitch_data} />}
        </div>
    );
};

export default PitchReportReport;