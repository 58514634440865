import React from "react";
import Typography from "@mui/material/Typography";

const Header = ({title}) => {
    return (
        <Typography variant="h4" component="h2" align="center">
            {title}
        </Typography>
    );
};

export default Header;