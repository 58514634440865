import React from "react";
import TypedText from "../../TypedText";
import Typography from "@mui/material/Typography";

const PitchNotes = ({notes}) => {
    return (
        <div className="pitch-report-notes">
            <Typography variant="h5" component="h3" align="center">
                Notes
            </Typography>
            <hr style={{marginTop: "0rem"}}/>
            <div className="pitch-report-notes-section">
                <TypedText text={notes} speed={50}/>
            </div>
        </div>
    );
};

export default PitchNotes;