import React, { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const customTheme = createTheme({
    palette: {
      mode: 'dark',  // This sets the dark mode
      primary: {
        main: '#fff',  // This sets the primary color to white
      },
    },
});

const PitchReportDateSelector = ({isStaff, athlete, date, setDate}) => {
    const [dates, setDates] = useState([]);

    useEffect(() => {
        setDates([]);
        setDate("");

        const fetchDates = async () => {
            const url = isStaff ? `/api/pitch-report/trainer-dates/${athlete.id}/` : "/api/pitch-report/athlete-dates/";
            await fetch(url, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":`Bearer ${localStorage.getItem("token")}`
                }
            })
            .then(response => response.json())
            .then(data => {
                setDates(data)
                if (data.length > 0) {
                    setDate(data[0]);
                }
            })
            .catch(error => console.log(error));
        };
        if (isStaff && athlete) {
            fetchDates();
        }
        if (!isStaff) {
            fetchDates();
        }
    }, [athlete]);

    return (
        <ThemeProvider theme={customTheme}>
            <InputLabel id="pitch-report-date-selector-label">Date</InputLabel>
            <Select
                labelId="pitch-report-date-selector-label"
                id="pitch-report-date-selector"
                sx={{ width: "25ch", alignSelf: "center"}}
                value={date}
                label="Date"
                onChange={event => setDate(event.target.value)}
            >
                {dates.map(dateData => {
                    return <MenuItem key={dateData.id} value={dateData}>{dateData.date}</MenuItem>
                })}
            </Select>
        </ThemeProvider>
    );
};

export default PitchReportDateSelector;