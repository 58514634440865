import React from "react";
import Button from "@mui/material/Button";

const SubmitNotification = ({setSubmitted, submitMsg}) => {

    const handleClick = () => {
        setSubmitted(false);
    }
    return (
        <>
            <div className="modal"></div>
            <div className="pitch-report-submit-notif">
                <div>{submitMsg}</div>
                <Button 
                    size="medium"
                    variant="outlined"
                    style={{ minWidth: "10%", width: "fit-content", padding: "0.5rem" }}
                    onClick={handleClick}
                >
                    Return to Reports
                </Button>
            </div>
        </>
    );
};

export default SubmitNotification;