import React from "react";
import Header from "./Header";
import PitchData from "./PitchData";
import PitchNotes from "./PitchNotes";
import PDFViewer from "./PDFViewer";

const ReportDetails = ({date, notes, file, pitchData}) => {
    return (
        <div className="pitch-report-details-container">
            <Header title={"Pitch Arsenal Report"} />
            <PitchData pitchData={pitchData} />
            <PitchNotes notes={notes} />
            {file && <PDFViewer file={file} date={date} />}
        </div>
    );
};

export default ReportDetails;